import React from 'react';

import Icon from './Icon';

export default function NavLinkIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="NavLinkIcon">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="nav-link">
          <path
            id="Icon"
            d="M5.50006 8.50872L4.50501 9.50157C2.84591 11.157 2.84441 13.8444 4.50167 15.5016C6.15759 17.1576 8.84237 17.1576 10.4983 15.5017L11.5001 14.5M14.5001 11.5L15.5001 10.5C17.1569 8.84307 17.1569 6.15682 15.5 4.49998C13.8431 2.8431 11.1568 2.84311 9.49994 4.5L8.5 5.49995M7.99997 11.9999L12 7.99992"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </Icon>
  );
}
