import React from 'react';

import Icon from './Icon';

export default function TagIcon({
  className = '',
  text = 'Tag',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.25 5.5C3.25 4.25736 4.25736 3.25 5.5 3.25H11.068C11.8637 3.25 12.6267 3.56607 13.1893 4.12868L20.4393 11.3787C21.6109 12.5503 21.6109 14.4497 20.4393 15.6213L15.6213 20.4393C14.4497 21.6109 12.5503 21.6109 11.3787 20.4393L4.12868 13.1893C3.56607 12.6267 3.25 11.8637 3.25 11.068V5.5ZM7 8.5C7 8.69698 7.0388 8.89204 7.11418 9.07402C7.18956 9.25601 7.30005 9.42137 7.43934 9.56066C7.57863 9.69995 7.74399 9.81044 7.92598 9.88582C8.10796 9.9612 8.30302 10 8.5 10C8.69698 10 8.89204 9.9612 9.07402 9.88582C9.25601 9.81044 9.42137 9.69995 9.56066 9.56066C9.69995 9.42137 9.81044 9.25601 9.88582 9.07402C9.9612 8.89204 10 8.69698 10 8.5C10 8.30302 9.9612 8.10796 9.88582 7.92598C9.81044 7.74399 9.69995 7.57863 9.56066 7.43934C9.42137 7.30005 9.25601 7.18956 9.07402 7.11418C8.89204 7.0388 8.69698 7 8.5 7C8.30302 7 8.10796 7.0388 7.92598 7.11418C7.74399 7.18956 7.57863 7.30005 7.43934 7.43934C7.30005 7.57863 7.18956 7.74399 7.11418 7.92598C7.0388 8.10796 7 8.30302 7 8.5Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
