import React from 'react';

import Icon from './Icon';

export default function IntegrationsIcon({
  className,
  text = 'integrations',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7 10.7993H6.30005L8.0993 12.6001"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.9008 5.39917L11.7 7.20067H6.30005"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15.75H6C3.92925 15.75 2.25 14.0707 2.25 12V6C2.25 3.92925 3.92925 2.25 6 2.25H12C14.0707 2.25 15.75 3.92925 15.75 6V12C15.75 14.0707 14.0707 15.75 12 15.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
