import React from 'react';

import Icon from './Icon';

export default function NavEmail({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="NavEmail">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="nav-email">
          <rect
            id="Rectangle 16"
            x="3"
            y="5"
            width="14"
            height="10"
            rx="2"
            stroke="currentColor"
            stroke-width="1.5"
          />
          <path
            id="Vector 19"
            d="M6 8L9.5 10.625C9.7963 10.8472 10.2037 10.8472 10.5 10.625L14 8"
            stroke="currentColor"
            stroke-width="1.5"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </Icon>
  );
}
