import React from 'react';

import Icon from './Icon';

export default function NavFeed({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="NavFeed">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.00015 10.8546C5.36476 10.8546 6.67347 11.3967 7.63839 12.3616C8.60331 13.3265 9.14539 14.6352 9.14539 15.9998C9.14539 14.6375 8.6017 13.3249 7.63839 12.3616C6.67507 11.3983 5.36248 10.8546 4.00015 10.8546Z"
          fill="currentColor"
        />
        <path
          d="M4 7.4273C6.27247 7.4273 8.45087 8.33541 10.0634 9.93658C11.6646 11.5491 12.5727 13.7275 12.5727 16C12.5727 13.7287 11.6695 11.5426 10.0634 9.93658C8.45736 8.33052 6.27131 7.4273 4 7.4273Z"
          fill="currentColor"
        />
        <path
          d="M16 16C16 12.8189 14.7296 9.76924 12.4883 7.51182C10.2309 5.27041 7.18119 4 4 4C7.18029 4 10.2395 5.26302 12.4883 7.51182C14.7371 9.76062 16 12.8197 16 16Z"
          fill="currentColor"
        />
        <path
          d="M7.63839 12.3616C6.67347 11.3967 5.36476 10.8546 4.00015 10.8546C5.36248 10.8546 6.67507 11.3983 7.63839 12.3616ZM7.63839 12.3616C8.60331 13.3265 9.14539 14.6352 9.14539 15.9998C9.14539 14.6375 8.6017 13.3249 7.63839 12.3616ZM10.0634 9.93658C8.45087 8.33541 6.27247 7.4273 4 7.4273C6.27131 7.4273 8.45736 8.33052 10.0634 9.93658ZM10.0634 9.93658C11.6646 11.5491 12.5727 13.7275 12.5727 16C12.5727 13.7287 11.6695 11.5426 10.0634 9.93658ZM12.4883 7.51182C14.7296 9.76924 16 12.8189 16 16C16 12.8197 14.7371 9.76062 12.4883 7.51182ZM12.4883 7.51182C10.2309 5.27041 7.18119 4 4 4C7.18029 4 10.2395 5.26302 12.4883 7.51182Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Icon>
  );
}
